const navItems = [
  {
    text: 'Buy cover',
    to: '/cover',
  },
  {
    text: 'Dashboard',
    to: '/dashboard',
  },
  {
    text: 'Stake',
    to: 'https://v2.nexusmutual.io/stake',
    external: true,
  },
  {
    text: 'Claims',
    to: '/claims',
  },
  {
    text: 'NXM',
    to: '/nxm',
  },
  {
    text: 'Governance',
    to: '/governance',
  },
];

export default navItems;
